import PhoneVerificationForm from '../phoneVerification'

const StepContent = [
  {
    subtitle: 'How do we source the best talent for your team?',
    flow: 'tutorial',
    stepperTitle: '',
    mainContent: () => (
      <>
        <h2 className='mb-4 text-xl font-bold xsm:text-base'>
          Read through our 5 stages of vetting
        </h2>
        <p className='text-base'>
          Our rigorous five step vetting process handpicks the most capable teammates from thousands
          of candidates centered around our Howdy Houses, vibrant community workspaces in six
          different countries that attract the brightest minds not found on online-only platforms,
          ensuring you connect with truly exceptional people.
        </p>
      </>
    ),
  },
  {
    title: 'SCREENING',
    stepperTitle: 'Screening',
    subtitle: 'We Save Your Valuable Time',
    flow: 'tutorial',
    mainContent: () => (
      <p className='text-base'>
        Save your recruiter's time with Howdy's sourcing and candidate vetting. We evaluate each
        candidate's English proficiency, relevant job experience, and genuine interest in joining
        your team. This step filters about 50% of candidates.
      </p>
    ),
  },
  {
    title: 'TECHNICAL INTERVIEW',
    stepperTitle: 'Technical Interview',
    subtitle: 'Assess Tech Stack Knowledge',
    flow: 'tutorial',
    mainContent: () => (
      <>
        <p className='mb-4 text-base'>
          Don't waste time on devs who can't tell the difference between a buffer and a stream,
          designers that don't know Figma, or customer success candidates that can't use JIRA!
        </p>
        <p className='text-base'>
          We evaluate your candidate's basic tech stack knowledge to save your team from wasting
          time. This step filters another 50% of candidates.
        </p>
      </>
    ),
  },
  {
    title: 'CODING CHALLENGE',
    stepperTitle: 'Coding Challenge',
    subtitle: 'Algorithm Session',
    flow: 'tutorial',
    mainContent: () => (
      <>
        <p className='mb-4 text-base'>
          <span className='font-bold'>This step is for devs only.</span> Our team does a one-hour
          coding challenge with candidates. We use HackerRank to administer the coding session
          focused on data structures and algorithms. We feel that good developers should know the
          difference between stacks, queues, hash tables, and can use them to express algorithms.
        </p>
        <p className='text-base'>
          This step filters another 30% of candidates and can use them to express real-world
          business value.
        </p>
      </>
    ),
  },
  {
    title: 'CULTURAL INTERVIEW',
    stepperTitle: 'Culture interview',
    subtitle: 'Behavioral Questions',
    flow: 'tutorial',
    mainContent: () => (
      <>
        <p className='mb-4 text-base'>
          Bad hires break companies, skills alone aren't enough, good teammates are great
          communicators and collaborators. We spend an hour asking candidates about their past
          challenges to filter out bad attitudes.
        </p>
        <p className='text-base'>
          Our business model relies on candidates succeeding long term on your team. We reject any
          candidate that we wouldn't hire ourselves. Only 1 in 200 make it through the entire
          process.
        </p>
      </>
    ),
  },
  {
    title: 'YOUR TEAM’S INTERVIEW',
    stepperTitle: 'Interview with you',
    subtitle: '',
    markTutorialAsReaded: true,
    flow: 'tutorial',
    mainContent: () => (
      <div className='text-base'>
        <p>
          It’s your turn to interview but move fast, the best candidates have multiple offers and
          they won’t jump through too many hoops!
        </p>
        <br />
        <ul>
          <li className='mb-4'>• Your team will do a 1 hour interview with the candidate.</li>
          <li className='mb-4'>
            • Your team will reply within 24 hours. Candidates hate it when we ghost them.
          </li>
          <li className='mb-4'>
            • If you don’t reply within 24 hours, we’ll have to resort to texting and Facebook
            stalking!
          </li>
        </ul>
      </div>
    ),
  },
  {
    flow: 'verifyInfo',
    mainContent: ({ handleBack, handleSuccess, phoneNumber }) => (
      <PhoneVerificationForm
        handleBack={handleBack}
        handleSucces={handleSuccess}
        phoneNumber={phoneNumber}
      />
    ),
    hideButtons: true,
  },
]

export default StepContent
