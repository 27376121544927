import React from 'react'

export default function CountryUnitedKingdom({ width = 32, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5750_45)'>
        <mask
          id='mask0_5750_45'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={32}
          height={32}
        >
          <path
            d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_5750_45)'>
          <g clipPath='url(#clip1_5750_45)'>
            <path
              d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
              fill='#F0F0F0'
            />
            <path
              d='M3.30746 6.25885C2.05065 7.89404 1.10296 9.77872 0.551147 11.8262H8.87477L3.30746 6.25885Z'
              fill='#0052B4'
            />
            <path
              d='M31.4487 11.8262C30.8969 9.77882 29.9492 7.89413 28.6924 6.25894L23.1252 11.8262H31.4487Z'
              fill='#0052B4'
            />
            <path
              d='M0.551147 20.174C1.10302 22.2214 2.05071 24.106 3.30746 25.7412L8.87459 20.174H0.551147Z'
              fill='#0052B4'
            />
            <path
              d='M25.7411 3.30755C24.1059 2.05074 22.2213 1.10305 20.1738 0.551178V8.87474L25.7411 3.30755Z'
              fill='#0052B4'
            />
            <path
              d='M6.25879 28.6924C7.89398 29.9492 9.77866 30.8969 11.826 31.4488V23.1253L6.25879 28.6924Z'
              fill='#0052B4'
            />
            <path
              d='M11.826 0.551178C9.7786 1.10305 7.89391 2.05074 6.25879 3.30749L11.826 8.87468V0.551178Z'
              fill='#0052B4'
            />
            <path
              d='M20.174 31.4488C22.2213 30.8969 24.106 29.9492 25.7411 28.6925L20.174 23.1253V31.4488Z'
              fill='#0052B4'
            />
            <path
              d='M23.1252 20.174L28.6924 25.7412C29.9492 24.1061 30.8969 22.2214 31.4487 20.174H23.1252Z'
              fill='#0052B4'
            />
            <path
              d='M31.8646 13.9131H18.0871H18.087V0.135438C17.4038 0.0465 16.7073 0 16 0C15.2926 0 14.5962 0.0465 13.9131 0.135438V13.9129V13.913H0.135438C0.0465 14.5962 0 15.2927 0 16C0 16.7074 0.0465 17.4038 0.135438 18.0869H13.9129H13.913V31.8646C14.5962 31.9535 15.2926 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871V18.087H31.8646C31.9535 17.4038 32 16.7074 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z'
              fill='#D80027'
            />
            <path
              d='M20.174 20.174L27.3137 27.3138C27.6421 26.9855 27.9553 26.6423 28.2542 26.2866L22.1416 20.174H20.174V20.174Z'
              fill='#D80027'
            />
            <path
              d='M11.8261 20.174H11.826L4.68628 27.3137C5.01453 27.6421 5.35772 27.9553 5.71347 28.2542L11.8261 22.1414V20.174Z'
              fill='#D80027'
            />
            <path
              d='M11.8261 11.8262V11.8261L4.68635 4.68625C4.35797 5.0145 4.04472 5.35769 3.74585 5.71344L9.85854 11.8261L11.8261 11.8262Z'
              fill='#D80027'
            />
            <path
              d='M20.174 11.8262L27.3138 4.68632C26.9855 4.35794 26.6423 4.04469 26.2866 3.74588L20.174 9.85857V11.8262Z'
              fill='#D80027'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_5750_45'>
          <rect width={32} height={32} fill='white' />
        </clipPath>
        <clipPath id='clip1_5750_45'>
          <rect width={32} height={32} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
