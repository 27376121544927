// @ts-nocheck
import React, { InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import { Field } from 'react-final-form'
import VisibilityEye from './VisibilityEye'
import MagnifyingGlass from './Icons/MagnifyingGlass'
import cn from 'classnames'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  required?: boolean
  textArea?: boolean
  rows?: number
  visibilityControlled?: boolean
  visibilityFieldName?: string
  input: InputHTMLAttributes<HTMLInputElement>
}

export default function Input(props: InputProps) {
  const {
    label = '',
    name,
    type = 'text',
    error,
    placeholder,
    textArea = false,
    rows = 8,
    required = false,
    disabled = false,
    input,
    visibilityControlled = false,
    visibilityFieldName,
    isSearchable = false,
    ...rest
  } = props
  const inputClassName = clsx(
    'px-3 py-[15px]',
    'text-base',
    'font-worksans',
    'placeholder-default',
    'focus:placeholder-focus',
    'rounded-lg',
    'w-full',
    'border-[#C1C1C1]',
    'text-howdy-black',
    'focus:text-howdy-primary-green',
    'focus:ring-0',
    'focus:border-howdy-primary-green',
    'leading-[22px]',
    'text-ellipsis',
    'resize-none',
    { 'border-howdy-orange': error },
    { 'focus:border-howdy-orange': error },
    { 'text-howdy-orange': error },
    { 'focus:text-howdy-orange': error },
    { 'placeholder-error': error },
    { 'focus:placeholder-error': error },
    { 'bg-howdy-disabled': disabled },
    { 'placeholder-disabled': disabled },
    { 'text-howdy-disabled-dark': disabled },
  )

  return (
    <div className={clsx('w-full')}>
      <label
        className={clsx(
          'block',
          'text-sm',
          'font-normal',
          'flex',
          'justify-between',
          'content-center',
        )}
        htmlFor={name}
      >
        <div>
          {required && <span className='mr-1 text-[#ED4F00]'>*</span>}
          {label}
        </div>
        {visibilityControlled && (
          <Field name={visibilityFieldName ? visibilityFieldName : `hiddenFields.${name}`}>
            {({ input }) => (
              <VisibilityEye
                visible={!input.value}
                name={input.name}
                onClick={() => input.onChange(!input.value)}
              />
            )}
          </Field>
        )}
      </label>
      <div className={'relative w-full'}>
        {textArea ? (
          <textarea
            className={inputClassName}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
            {...input}
          />
        ) : (
          <input
            className={cn(inputClassName, { 'pr-10': isSearchable })}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            {...input}
            {...rest}
          />
        )}
        {isSearchable && (
          <span className='absolute right-3 top-0 flex h-full items-center'>
            <MagnifyingGlass color='#449386' />
          </span>
        )}
      </div>
      {error && <p className='ml-3 mt-1 text-xs text-[#ED4F00]'>{error}</p>}
    </div>
  )
}
