import React, { ReactNode, useEffect } from 'react'
import ReactModal, { Props } from 'react-modal'
import CloseIcon from '../Icons/CloseIcon'
import useMobile from '../../../../hooks/useMobile'
import { cn } from '@howdy/core/lib/utils'

interface IBase extends Props {
  children: ReactNode
  onRequestClose?: () => void
  isOpen: boolean
  zIndex?: number
  fullScreen?: boolean
}

const Modal = ({
  isOpen,
  onRequestClose,
  className,
  children,
  fullScreen = false,
  zIndex = 1000,
}: IBase) => {
  const { isMobile } = useMobile()

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <ReactModal
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? 'calc(100% - 40px)' : 'auto',
          maxHeight: isMobile ? 'calc(100vh - 40px)' : 'unset',
          overflow: 'visible',
          borderRadius: '8px',
          height: isMobile && fullScreen ? '100%' : 'unset',
          zIndex: 200,
        },
        overlay: {
          zIndex,
          backgroundColor: '#000000d9',
        },
      }}
      isOpen={isOpen}
    >
      <div className={cn('relative h-full w-full md:min-w-[458px]', className)}>
        {onRequestClose && (
          <button
            className='absolute right-0 top-0 z-[201] text-howdy-primary-green'
            onClick={onRequestClose}
          >
            <CloseIcon />
          </button>
        )}
        <div className='flex max-h-[98%] flex-col items-center overflow-y-auto text-[#464646]'>
          {children}
        </div>
      </div>
    </ReactModal>
  )
}

export default Modal
