import Link from 'next/link'

export default function Footer(props) {
  const { isAdminPage = false } = props

  return (
    <div className='relative bottom-0 z-40 flex h-auto w-full flex-col items-center justify-start gap-3 bg-[#1B1B1B] px-4 py-[48px] text-white shadow-[0_-2px_6px_0px_rgb(0,0,0,0.1)] md:sticky md:mt-auto md:h-[24px] md:flex-row md:justify-end md:bg-[#F2F2F2] md:py-0 md:text-[#1B1B1B] lg:mt-[25px] lg:px-14'>
      <p className='text-base md:text-sm'>Copyright ©Howdy {new Date().getFullYear()}</p>

      <p className='hidden md:block'>|</p>
      {!isAdminPage && (
        <div className='flex flex-col items-center gap-3 text-base text-[#65C6B7] underline md:flex-row md:text-sm md:text-[#1B1B1B] md:no-underline'>
          <Link href={'/privacy-policy'} target='_blank'>
            <p>Privacy Policy</p>
          </Link>
          <p className='hidden md:block'>|</p>
          <Link href={'/terms-conditions'} target='_blank'>
            <p>T&C</p>
          </Link>
          <p className='hidden md:block'>|</p>
          <Link href={'/help'} target='_blank'>
            <p>Help</p>
          </Link>
        </div>
      )}
    </div>
  )
}
