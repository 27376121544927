import classNames from 'classnames'
import useMobile from '../hooks/useMobile'
import { useEffect } from 'react'

export default function Modal({
  display,
  size = 'lg',
  width,
  children,
  rounded = true,
  className,
  fullscreen = false,
  darkBg = false,
}) {
  const { isMobile } = useMobile()
  useEffect(() => {
    if (display) {
      document.body.classList.add('modalOpen')
    } else {
      document.body.classList.remove('modalOpen')
    }

    return () => {
      document.body.classList.remove('modalOpen')
    }
  }, [display])

  if (!display) {
    return null
  }
  return (
    <div
      className={`fixed inset-0 z-[150] flex max-h-full w-full items-center justify-center overflow-y-hidden text-center xsm:py-0 ${className}`}
    >
      <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
        <div
          className={classNames('absolute inset-0 opacity-75', {
            'bg-black': darkBg,
            'bg-gray-500': !darkBg,
          })}
        ></div>
      </div>
      {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
      <span className='hidden sm:inline-block sm:min-h-screen sm:align-middle' aria-hidden='true'>
        &#8203;
      </span>
      <div
        className={classNames(
          `md:w-auto max-w-${size} ${
            fullscreen ? 'h-full w-full' : 'w-[85%]'
          } inline-block transform overflow-y-auto overflow-x-hidden bg-white text-left align-bottom shadow-xl transition-all sm:my-0 sm:align-middle`,
          { 'rounded-lg': rounded },
          `lg:w-${width || 'max'}`,
          'lg:max-w-[85%]',
          { 'max-h-[90%]': !isMobile },
          className,
        )}
        role='dialog'
        aria-modal='true'
      >
        {children}
      </div>
    </div>
  )
}
